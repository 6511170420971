<template>
  <transition name="fade" mode="out-in">
    <div v-if="!showModal">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Vendedores</h4>

        <div class="row col-sm-12 justify-content-end container-filters-seller">
          <base-input>
            <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome" v-model="Filters.name"></el-input>
          </base-input>

          <base-input>
            <el-select class="select-primary mb-3 pagination-select" v-model="Filters.type" placeholder="Per page">
              <el-option class="select-primary" v-for="item in Filters.typeOptions" :key="item.name" :label="item.name"
                :value="item.value"></el-option>
            </el-select>
          </base-input>

          <base-input>
            <button class="custom-btn-filter" @click="FilterSellers()">
              Filtrar
            </button>
          </base-input>

          <base-input>
            <button class="custom-btn-filter" @click="GetListOfSellers()">
              Limpar filtro
            </button>
          </base-input>

          <div v-if="checkFunctionality">
            <base-input>
              <button class="custom-btn-add" @click="AddNewSeller()" v-if="getUserPermission('37_c')">
                Novo
              </button>
            </base-input>
          </div>
        </div>

        <div>
          <svg v-if="showSpinner" class="spinner-lg" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>

          <el-table :data="queriedData">
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
              :prop="column.prop" :label="column.label" align="center"></el-table-column>

            <el-table-column :min-width="135" align="right" label="Ações">
              <div slot-scope="props">
                <base-button class="like !important btn-link" type="primary" size="sm" icon @click="ShowModal(props.row)"
                  v-if="getUserPermission('37_c')">
                  <i class="material-icons">mode_edit_outline</i>
                </base-button>
                <base-button class="like !important btn-link" type="danger" size="sm" icon @click="Delete(props.row)">
                  <i class="material-icons" v-if="getUserPermission('37_c')">delete_outline</i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>

        <div slot="footer" class="
            col-12
            d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
          <div class="col-5"></div>

          <div class="col-2">
            <label>
              Exibindo
              <span class="primary-text">{{ from + 1 }}</span> -
              <span class="primary-text">{{ to }}</span> de
              <span class="primary-text">{{ total }}</span> registros
            </label>
          </div>

          <div class="col-3">
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="total"></base-pagination>
          </div>

          <div class="col-2">
            <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
              v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </card>
    </div>

    <div class="row" v-else>
      <div class="col-12">
        <h4 slot="header" class="card-title">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="HideModal()">
            <i class="material-icons">arrow_back</i>
          </base-button>
          Detalhes do Vendedor
        </h4>
      </div>

      <div class="col-12">
        <card type="tasks" class="stacked-form">
          <div class="row">
            <div class="col-12">
              <h4 class="card-title">Referência no Registro</h4>
              <base-input>
                <el-input placeholder="Informe a Referência no Registro" v-model="Seller.Name"></el-input>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <h4 class="card-title">Referência no E-Commerce</h4>
              <base-input>
                <el-input placeholder="Informe a Referência no E-Commerce" v-model="Seller.EcommerceReference"></el-input>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-12 table-full-width table-responsive">
              <h4 class="card-title">Referência no HUB</h4>
              <base-input>
                <el-input placeholder="Informe a Referência no HUB" v-model="Seller.HubReference"></el-input>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-12 table-full-width table-responsive">
              <h4 class="card-title">Referência no ERP</h4>

              <div v-if="!enableSAP4HANA">
                <base-input>
                  <el-input placeholder="Nome" v-model="Seller.ErpReference"></el-input>
                </base-input>
              </div>
              <div v-else>
                <base-input>
                  <el-select class="select-primary mb-3 pagination-select" v-model="Seller.ErpReference"
                    placeholder="Informe a Referência no ERP">
                    <el-option class="select-primary" v-for="item in erpReferenceList.data" :key="item.SalesEmployeeName"
                      :label="item.SalesEmployeeName" :value="item.SalesEmployeeName"></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
        </card>
      </div>
      <div class="col-lg-12 col-sm-12">
        <UpdateLog :dataLog="dataLog" />
      </div>
      <div class="col-12">
        <div class="
            d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
          <div></div>
          <div>
            <button class="custom-btn-save" @click="SaveSeller()">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
import planMixin from "src/mixins/planMixin.js";
import swal from "sweetalert2";
import { BasePagination } from "src/components";
import { BaseRadio } from "src/components/index";
import {
  ValidateForm,
  ShowWarningNotification,
  FiltersValidation,
} from "./Data/validations";
import { tableColumns } from "./Data/columns.js";
import UpdateLog from "../../components/UpdateLog.vue";
import { mapState } from "vuex";
import constants from "@/util/constants";

export default {
  mixins: [planMixin],
  components: {
    BaseRadio,
    BasePagination,
    UpdateLog
  },
  computed: {
    ...mapState(['ecommerce']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      dataLog: [],
      erpTable: [],
      showModal: false,
      toInsert: true,
      tableData: [],
      searchedData: [],
      showSpinner: false,
      response: null,
      realm: null,
      Filters: {
        type: null,
        name: null,
        typeOptions: [
          {
            name: "Filtrar por...",
            value: null
          },
          {
            name: "Nome",
            value: "Name"
          },
          {
            name: "Nome no HUB",
            value: "HubReference"
          },
          {
            name: "Nome do E-commerce",
            value: "EcommerceReference"
          },
          {
            name: "Nome no ERP",
            value: "ErpReference"
          },
        ]
      },
      Seller: {
        Id: 0,
        Name: null,
        EcommerceReference: null,
        ErpReference: null,
        HubReference: null,
        EcommerceId: this.$store.state.ecommerce.Id
      },
      tableColumns,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      erpReferenceList: [],

      isSapHana: false,
      enableSAP4HANA: false,

      checkFunctionality: true,
    };
  },
  mounted: async function () {
    this.GetErpPlatformType(this.ecommerce.Erp.Platform);

    await this.GetListOfSellers();
    await this.GetListOfErps();

    this.EnableErpReferenceManual();

    // await this.getPlan();
    // await this.getFunctionalities(this.isPlanControlActive);
  },
  methods: {
    GetListOfSellers: async function () {
      this.showSpinner = true;

      const { data } = await this.$bahngleis.get(`/Seller/GetPaginated?ecommerceName=${this.$store.state.ecommerce.Name}`);
      this.Filters.name = null;
      this.Filters.type = null;
      this.tableData = data.Items;
      this.showSpinner = false;
    },
    FilterSellers: async function () {
      if (!FiltersValidation(this, this.Filters)) return;

      this.showSpinner = true;

      const { data } = await this.$bahngleis.get(
        `/Seller/GetPaginated?ecommerceName=${this.$store.state.ecommerce.Name}&${this.Filters.type}=${this.Filters.name}`
      );

      this.tableData = data.Items;
      this.showSpinner = false;
    },
    SaveSeller: async function () {
      if (!ValidateForm(this, this.Seller)) return;

      var self = this;

      if (self.toInsert) self.InsertSeller();
      else self.UpdateSeller();
    },
    InsertSeller: async function () {
      var self = this;

      this.Seller.EcommerceId = this.ecommerce.Id;

      await this.$bahngleis
        .post(`/Seller`, this.Seller)
        .then(async () => {
          this.DisplayMessageResponse(
            "Sucesso",
            "O vendedor foi inserido",
            "success"
          );
          await this.GetListOfSellers();
          this.HideModal();
          self.showDetails = false;
          self.showSpinner = false;
        })
        .catch(() =>
          this.DisplayMessageResponse(
            "Atenção",
            "Ocorreu um erro ao inserir o vendedor",
            "error"
          )
        );
    },
    UpdateSeller: async function () {
      var self = this;
      this.showSpinner = true;

      await this.$bahngleis
        .put(`/Seller`, this.Seller)
        .then(() => {
          this.DisplayMessageResponse(
            "Sucesso",
            "O vendedor foi atualizado",
            "success"
          );
          self.showDetails = false;
          self.showSpinner = false;
          this.HideModal();
        })
        .catch(() =>
          this.DisplayMessageResponse(
            "Atenção",
            "Ocorreu um erro ao inserir o vendedor",
            "error"
          )
        );
    },
    Delete: async function (seller) {
      var self = this;
      this.Seller = seller;
      swal({
        title: "Gostaria de excluir o vendedor?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then((result) => {
        if (result.value) {
          var pos = this.tableData.indexOf(seller);
          this.tableData.splice(pos, 1);
          self.Del();
        }
      });
    },

    Del: async function () {
      await this.$bahngleis
        .delete(`/Seller/${this.Seller.Id}`)
        .then(function (response) {
          if (response.status === 200) {
            swal({
              title: "Sucesso",
              text: "O vendedor foi excluido com sucesso",
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
            self.showDetails = false;
          } else {
            swal({
              title: "Atenção",
              text: "Ocorreu um erro ao excluir o vendedor",
              type: "error",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
    },
    ShowModal: async function (seller) {
      this.Seller = seller;
      this.toInsert = false;
      this.showModal = !this.showModal;

      let responseLog = await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        this.Seller.ID +
        "&entity=Seller"
      );

      this.dataLog = await responseLog.data;
    },
    HideModal: async function () {
      this.Seller = null;
      this.showModal = !this.showModal;
    },
    AddNewSeller: async function () {
      this.Seller = {
        Id: 0,
        Name: null,
        EcommerceReference: null,
        ErpReference: null,
        HubReference: null,
      };

      this.dataLog = [];
      this.toInsert = true;
      this.showModal = !this.showModal;
    },
    DisplayMessageResponse: async function (title, text, type) {
      swal({
        title,
        text,
        type,
        confirmButtonClass: `btn btn-success btn-fill`,
        buttonsStyling: false,
      });
    },
    GetListOfErps: async function () {
      this.erpReferenceList = await this.$bahngleis.get("SAP/SalesPersons", {
        params: {
          ecommerceName: this.$store.state.ecommerce.Name,
        },
      });
    },
    GetErpPlatformType(key) {
      this.isSapHana = ("Sap Hana").includes(constants.ErpPlatform.find((a) => a.Value === key).Label);
    },
    EnableErpReferenceManual() {
      if (this.isSapHana) {
        this.enableSAP4HANA = true;
      } else {
        this.enableSAP4HANA = false;
      }
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
  },
};
</script>

<style>
@import "./Seller.css";
</style>
