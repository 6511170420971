<template>
  <div>
    <div class="row">
      <arrow-back :title="'Edição da configuração de depósito'" :route-name="'WarehouseConfiguration'"></arrow-back>
    </div>
    <div class="col-lg-12 col-sm-12 specify-margin">
      <card class="stacked-form ">
        <div class="row">
          <div class="col-5">
            <div>
              <label>Depósito SAP</label>
              <base-input placeholder="Informe o código" v-model="configuration.Code" />
            </div>
            <div>
              <label>Filial SAP</label>
              <base-input placeholder="Informe a filial" v-model="configuration.Filial" />
            </div>
            <div>
              <label>Depósito e-commerce</label>
              <base-input placeholder="Informe o depósito" v-model="configuration.WarehouseEcommerce" />
            </div>
            <div>
              <label>Depósito de Transferência</label>
              <base-input placeholder="Informe o depósito de Transferência" v-model="configuration.WarehouseTransfer" />
            </div>
            <div>
              <label>Depósito NF de Entrada</label>
              <base-input placeholder="Informe o depósito NF de Entrada"
                v-model="configuration.WarehousePurchaseInvoice" />
            </div>
            <div>
              <base-input label="Organização">
                <el-select class="select-primary" v-model="configuration.OrganizationId"
                  placeholder="Informe a Organização" filterable>
                  <el-option v-for="item in organizationsToSelectField" :key="item.Value" :label="item.Label"
                    :value="item.Value">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div>
              <label>Centro</label>
              <base-input placeholder="Informe o Centro" v-model="configuration.Plant" />
            </div>
          </div>

          <div class="col-7 check-form">
            <div class="col text-center">
              <label for="">Ativo</label>
              <base-checkbox v-model="configuration.Active"> </base-checkbox>
            </div>
            <div class="col text-center">
              <label for="">Integração de pedido</label>
              <base-checkbox v-model="configuration.IsIntegration">
              </base-checkbox>
            </div>
            <div class="col text-center">
              <label for="">Consulta estoque</label>
              <base-checkbox v-model="configuration.ReadOnly"> </base-checkbox>
            </div>
            <div class="col text-center">
              <label for="">Picking</label>
              <base-checkbox v-model="configuration.Picking"> </base-checkbox>
            </div>
            <div class="col text-center">
              <label for="">Ignorar saldo de Estoque?</label>
              <base-checkbox v-model="configuration.IgnoreInventoryBalance">
              </base-checkbox>
            </div>
            <div class="col text-center">
              <label for="">Calcular IPI?</label>
              <base-checkbox v-model="configuration.MustCalculateIpi">
              </base-checkbox>
            </div>
          </div>
        </div>
      </card>
      <UpdateLog :dataLog="dataLog" />
      <div class="col-12 table-full-width table-responsive">
        <div class="d-flex justify-content-center justify-content-between flex-wrap">
          <div></div>
          <div>
            <button class="custom-btn-save" @click="save()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowBack from "src/components/ArrowBack/ArrowBack";
import { BaseCheckbox } from "src/components/index";
import swal from "sweetalert2";
import UpdateLog from "../../../components/UpdateLog.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ArrowBack,
    BaseCheckbox,
    UpdateLog
  },
  computed: {
    ...mapGetters("organization", ["organizationsToSelectField"])
  },
  data() {
    return {
      dataLog: [],
      //isSAPHANA: false,
      configuration: {
        Id: null,
        Name: null,
        Code: null,
        Filial: null,
        WarehouseEcommerce: null,
        WarehouseTransfer: null,
        WarehousePurchaseInvoice: null,
        ReadOnly: false,
        IsIntegration: false,
        Active: false,
        Picking: false,
        IgnoreInventoryBalance: false,
        MustCalculateIpi: false,
        EcommerceName: this.$store.state.ecommerce.Name,
        OrganizationId: "",
        Plant: null
      }
    };
  },
  async mounted() {
    let response = await this.$bahngleis.get(
      "configuration/warehouse/" + this.$route.params.id
    );
    this.configuration = response.data;

    let responseLog = await this.$bahngleis.get(
      "/LogAction/getlogsofentity?identity=" +
      this.$route.params.id +
      "&entity=Warehouse"
    );

    this.dataLog = await responseLog.data;

    await this.getOrganizationsByEcommerce();
    //await this.GetErpPlatformType();
  },
  methods: {
    ...mapActions("configurations", ["getOrganizations"]),
    ...mapActions("organization", ["getOrganizationsByEcommerce"]),
    save: async function () {
      if (!this.validateToSave()) {
        return;
      }
      let vm = this;
      let response = await this.$bahngleis
        .put("configuration/warehouse", this.configuration)
        .then(function () {
          swal({
            title: "Sucesso",
            text: "O depósito foi atualizado.",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false
          });
          vm.$router.push({ name: "WarehouseConfiguration" });
        })
        .catch(function (error) {
          let message = error.response.data ? error.response.data : "Ocorreu um erro ao atualizar o cadastro."
          swal({
            title: "Atenção",
            text: message,
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false
          });
        });
    },

    validateToSave() {
      let message = "";

      if (!this.configuration.Code) {
        message = "Por favor, informe o Depósito SAP";
      }

      if (!this.configuration.WarehouseEcommerce) {
        message = "Por favor, informe o Depósito e-commerce";
      }

      if (message != "") {
        this.$notify({
          message: message,
          timeout: 3000,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
        return false;
      }

      return true;
    },
    // GetErpPlatformTypeByKey(key) {
    //   return ("Sap Hana").includes(constants.ErpPlatform.find((a) => a.Value === key).Label);
    // },
    // async GetErpPlatformType() {
    //   this.isSAPHANA = this.GetErpPlatformTypeByKey(this.ecommerce.Erp.Platform);
    // }
  }
};
</script>

<style>
.check-form {
  display: flex;
  margin-top: 1.4em;
}
</style>
