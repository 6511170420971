<template>
  <Modal :modalContentClasses="'card'" :showClose="false" :show="show">
    <template v-slot:header>
      <h4 class="card-title">Adicionar Condição</h4>
    </template>
    <div class="row">
      <div class="col-12 col-sm-1">
        <base-input label="Ativo">
          <toggle-button class="ma-3" :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
            v-model="form.Active" :sync="true" />
        </base-input>
      </div>
      <div class="col-12 col-sm-12">
        <base-input label="Campo para condição *" :error="errorsCustomFields.platformFieldTarget">
          <el-select class="select-primary" v-model="form.PlatformFieldTarget">
            <el-option v-for="option in conditionFields" :value="option.Key" :label="option.Label"
              :key="option.Key"></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-sm-12">
        <base-input label="Tipo da condição *" :error="errorsCustomFields.conditionType">
          <el-select class="select-primary" v-model="form.ConditionType">
            <el-option v-for="option in conditionTypes" :value="option.Key" :label="option.Label"
              :key="option.Key"></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12 col-sm-12">
        <base-input :error="errorsCustomFields.conditionValue">
          <label v-if="[11, 12].includes(form.ConditionType) && form.PlatformFieldTarget === 1">Valor da condição (separados por ;) *</label>
          <label v-else>Valor da condição *</label>
          <el-select v-if="form.PlatformFieldTarget == 4" class="select-primary" v-model="form.ConditionValue" filterable>
            <el-option v-for="option in brazilianStates" :value="option.Key" :label="option.Label"
              :key="option.Key"></el-option>
          </el-select>
          <el-select v-else-if="form.PlatformFieldTarget == 3" class="select-primary" v-model="form.ConditionValue"
            filterable>
            <el-option v-for="option in orderRuleTaxIdentification" :value="option.Key" :label="option.Label"
              :key="option.Key"></el-option>
          </el-select>
          <el-select v-else-if="form.PlatformFieldTarget == 6" class="select-primary" v-model="form.ConditionValue"
            filterable>
            <el-option v-for="option in orderRuleRegion" :value="option.Key" :label="option.Label"
              :key="option.Key"></el-option>
          </el-select>
          <el-select v-else-if="form.PlatformFieldTarget == 7" class="select-primary" v-model="form.ConditionValue"
            filterable>
            <el-option v-for="option in shippingMethods" :value="option.value" :label="option.name"
              :key="option.value"></el-option>
          </el-select>
          <el-select v-else-if="form.PlatformFieldTarget == 8" class="select-primary" v-model="form.ConditionValue"
            filterable>
            <el-option v-for="option in paymentMethods" :value="option.value" :label="option.name"
              :key="option.value"></el-option>
          </el-select>
          <el-select v-else-if="form.PlatformFieldTarget == 9" class="select-primary" v-model="form.ConditionValue"
            filterable>
            <el-option v-for="option in channels" :value="option.Key" :label="option.Label"
              :key="option.Key"></el-option>
          </el-select>
          <el-input v-else placeholder="Valor da condição" v-model="form.ConditionValue"></el-input>
        </base-input>
      </div>
    </div>
    <template v-slot:footer>
      <button type="button" @click="addCondition" class="custom-btn-save mx-auto " aria-label="Close">
        Adicionar
      </button>
      <button type="button" @click="closeModal" class="custom-btn-add mx-auto" aria-label="Close">
        Cancelar
      </button>
    </template>
  </Modal>
</template>
<script>
import Modal from '@/components/Modal'
import constants from '@/util/constants'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState("customfield", ["paymentMethods", "shippingMethods","channels"]),
  },
  components: {
    Modal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      form: {
        PlatformFieldTarget: null,
        ConditionType: null,
        ConditionValue: null,
        Active: true
      },
      errorsCustomFields: {
        platformFieldTarget: null,
        conditionType: null,
        conditionValue: null
      },
      conditionTypes: constants.CustomFieldConditionType,
      conditionFields: constants.OrderRuleConditionField,
      brazilianStates: constants.BrazilianStates,
      orderRuleRegion: constants.OrderRuleRegion,
      orderRuleTaxIdentification: constants.OrderRuleTaxIdentification,
    }
  },
  methods: {
    addCondition() {
      if (!this.validate())
        return

      this.$emit("evtAddedCondition", this.form);
      this.form = {
        PlatformFieldTarget: null,
        ConditionType: null,
        ConditionValue: null,
        Active: true
      };
    },
    closeModal() {
      this.$emit("evtClose");
    },
    validate() {
      let valid = true;
      let error = "Campo obrigatório"
      if (this.$isNullOrEmpty(this.form.ConditionType)) {
        this.errorsCustomFields.conditionType = error;
        valid = false;
      }
      if (this.$isNullOrEmpty(this.form.PlatformFieldTarget)) {
        this.errorsCustomFields.platformFieldTarget = error;
        valid = false;
      }
      if (this.$isNullOrEmpty(this.form.ConditionValue) && this.form.ConditionType !== 8) {
        this.errorsCustomFields.conditionValue = error;
        valid = false;
      }

      return valid;
    },
  }
}
</script>
