<template>
  <div class="card-conditions">
    <div class="d-flex justify-content-between">
      <h4 class="card-title">Condições</h4>
      <base-button type="primary" small class="btn-next" @click="showModalCondition">
        Adicionar Condição
      </base-button>
    </div>
    <div v-if="listConditions.length > 0">
      <div class="row" v-for="(condition, index) in listConditions" :key="index">
        <hr class="spacer">
        <div class="col-12 col-sm-1">
          <base-input label="ID">
            <el-input disabled v-model="condition.Id"></el-input>
          </base-input>
        </div>
        <div class="col-12 col-sm-3">
          <base-input label="Campo para condição">
            <el-select class="select-primary" v-model="condition.PlatformFieldTarget" disabled>
              <el-option v-for="option in conditionFields" :value="option.Key" :label="option.Label"
                :key="option.Key"></el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-sm-3">
          <base-input label="Tipo da condição">
            <el-select class="select-primary" v-model="condition.ConditionType">
              <el-option v-for="option in conditionTypes" :value="option.Key" :label="option.Label"
                :key="option.Key"></el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-12 col-sm-3">
          <base-input label="Valor da condição">
            <el-select v-if="condition.PlatformFieldTarget == 4" class="select-primary"
              v-model="condition.ConditionValue">
              <el-option v-for="option in brazilianStates" :value="option.Key" :label="option.Label"
                :key="option.Key"></el-option>
            </el-select>
            <el-select v-else-if="condition.PlatformFieldTarget == 3" class="select-primary"
              v-model="condition.ConditionValue">
              <el-option v-for="option in orderRuleTaxIdentification" :value="option.Key" :label="option.Label"
                :key="option.Key"></el-option>
            </el-select>
            <el-select v-else-if="condition.PlatformFieldTarget == 6" class="select-primary"
              v-model="condition.ConditionValue">
              <el-option v-for="option in orderRuleRegion" :value="option.Key" :label="option.Label"
                :key="option.Key"></el-option>
            </el-select>
            <el-input v-else placeholder="Valor da condição" v-model="condition.ConditionValue"></el-input>
          </base-input>
        </div>
        <div class="col-12 col-sm-2 d-flex align-items-center">
          <base-input label="Ativo">
            <toggle-button class="ma-3" :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input
              type="checkbox" v-model="condition.Active" :sync="true" />
          </base-input>
          <base-button class="edit btn-link ml-4" type="danger" size="sm" @click="removeCondition(index)">
            <i class="material-icons">delete_outline</i>
          </base-button>
        </div>
      </div>
    </div>
    <h5 class="d-flex justify-content-center" v-else>Nenhuma condição cadastrada</h5>
    <OrderRuleConditionModal :show="showModal" @evtAddedCondition="saveCondition" @evtClose="closeModalCondition" />
  </div>
</template>
<script>
import constants from '@/util/constants'
import OrderRuleConditionModal from './OrderRuleConditionModal'

export default {
  name: "OrderRuleConditions",
  components: {
    OrderRuleConditionModal
  },
  props: {
    conditions: {
      type: Array
    }
  },
  data() {
    return {
      listConditions: this.conditions,
      conditionTypes: constants.CustomFieldConditionType,
      conditionFields: constants.OrderRuleConditionField,
      brazilianStates: constants.BrazilianStates,
      orderRuleRegion: constants.OrderRuleRegion,
      orderRuleTaxIdentification: constants.OrderRuleTaxIdentification,
      showModal: false,
    }
  },
  watch: {
    conditions(newData) {
      this.listConditions = newData;
    }
  },
  methods: {
    showModalCondition() {
      this.showModal = true;
    },
    saveCondition(model) {
      this.listConditions.push({ ...model });
      this.showModal = false;
    },
    closeModalCondition() {
      this.showModal = false;
    },
    removeCondition(index) {
      this.listConditions.splice(index, 1);
    },
    value() {
      return this.listConditions;
    }
  }
}
</script>
<style scoped>
.card-conditions {
  margin-top: 2em;
}

.spacer {
  width: 100%;
  text-align: center;
  margin-left: auto;
  color: #2b3553;
  background-color: #2b3553
}
</style>

