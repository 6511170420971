<template>
  <div>
    <div class="col-12">
      <h4 slot="header" class="card-title">
        <base-button class="like !important btn-link" type="primary" size="sm" icon
          @click="$router.push({ name: 'Ecommerce' })">
          <i class="material-icons">arrow_back</i>
        </base-button>
        Detalhe do E-commerce
      </h4>
    </div>
    <div class="col-md-12">
      <card type="tasks">
        <h4 class="card-title">Nome do e-commerce</h4>
        <div class="form-group table-full-width table-responsive">
          <base-input class="col-md-4">
            <el-input :disabled="true" placeholder="Nome do e-commerce" v-model="ecommerce.Name"></el-input>
          </base-input>
        </div>
        <h4 class="card-title">URL</h4>
        <div class="form-group table-full-width table-responsive">
          <base-input class="col-md-4">
            <el-input placeholder="url" v-model="ecommerce.Url" @input="isValidUrl()" @change="isValidUrl()"></el-input>
          </base-input>
          <div class="validate-url" v-if="!ValidUrl">URL inválida</div>
        </div>

        <h4 class="card-title">ERP</h4>
        <div class="form-group table-full-width table-responsive">
          <div class="col-md-4">
            <el-select class="select-primary" v-model="ecommerce.Erp.Platform">
              <el-option :name="option.label" v-for="option in erpList" :value="option.value" :label="option.label"
                :key="option.label"></el-option>
            </el-select>
          </div>
        </div>
        <h4 class="card-title">Plataforma</h4>
        <div class="form-group table-full-width table-responsive">
          <div class="col-md-4">
            <el-select class="select-primary" v-model="ecommerce.Platform">
              <el-option :name="option.label" v-for="option in platFormList" :value="option.value" :label="option.label"
                :key="option.label"></el-option>
            </el-select>
          </div>
        </div>
        <h4 class="card-title">ID da Loja</h4>
        <div class="form-group table-full-width table-responsive">
          <base-input class="col-md-4">
            <el-input placeholder="ID da Loja" v-model="ecommerce.EcommerceStoreId"></el-input>
          </base-input>
        </div>
        <h4 class="card-title">Chave de Integração</h4>
        <div class="form-group table-full-width table-responsive">
          <base-input class="col-md-4">
            <el-input placeholder="Chave de Integração" v-model="ecommerce.Code"></el-input>
          </base-input>
        </div>
        <h4 class="card-title">Status</h4>
        <div class="form-group table-full-width table-responsive">
          <base-input class="col-md-4">
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              v-model="ecommerce.Active" :sync="true" />
          </base-input>
        </div>
      </card>
    </div>
    <div class="col-lg-12 col-sm-12">
      <UpdateLog :dataLog="dataLog" />
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-center justify-content-between flex-wrap">
        <div></div>
        <div>
          <button class="custom-btn-save" @click="update">Atualizar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import UpdateLog from "../../components/UpdateLog.vue"
export default {
  components: {
    UpdateLog
  },
  data() {
    return {
      platFormList: this.$route.params.platFormList,
      erpList: this.$route.params.erpList,
      dataLog: [],
      ecommerce: {
        Id: 0,
        Name: "",
        Url: "",
        Code: "",
        Erp: {
          Id: 0,
          Platform: 0
        },
        UpdateStockInExternalApi: null,
        Active: null,
        Platform: 0,
        HubId: null,
        GoogleCertified: null
      },
      ecommercePasswordType: "password",
      ecommercePasswordIcon: "fa fa-eye",
      ValidUrl: false,
      regexUrl:
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
    };
  },
  computed: {
    ...mapState(["company"]),
    ...mapState("ecommerceView", ["ecommerceCurrent"]),
  },
  methods: {
    async update() {
      try {
        if (!this.validate()) return;
        await this.$bahngleis.put(`Ecommerce`, this.ecommerce);
        this.$showSuccess("E-commerce atualizado com sucesso.");
        this.$router.push({ name: "Ecommerce" });
      } catch (error) {
        this.$showError(error);
      }
    },
    async setEcommerceCurrent() {
      this.ecommerce.Id = this.ecommerceCurrent.Id;
      this.ecommerce.Name = this.ecommerceCurrent.Name;
      this.ecommerce.Url = this.ecommerceCurrent.Url;
      this.ecommerce.Platform = this.ecommerceCurrent.Platform;
      this.ecommerce.Erp = this.ecommerceCurrent.Erp;
      this.ecommerce.hubId = this.ecommerceCurrent.HubId;
      this.ecommerce.EcommerceStoreId = this.ecommerceCurrent.EcommerceStoreId;
      this.ecommerce.Code = this.ecommerceCurrent.Code;
      this.ecommerce.Active = this.ecommerceCurrent.Active;
      this.ecommerce.GoogleCertified = this.ecommerceCurrent.GoogleCertified;

      let responseLog = await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        this.ecommerceCurrent.Id +
        "&entity=Ecommerce"
      );

      this.dataLog = await responseLog.data;
    },
    validate() {
      if (this.ecommerce.Name == null) {
        this.$showError("Por favor, informe o nome do <b>E-Commerce</b>");
        return false;
      } else if (this.ecommerce.Url == null) {
        this.$showError("Por favor, informe a URL do <b>E-Commerce</b>");
        return false;
      } else if (this.ecommerce.Platform == null) {
        this.$showError("Por favor, informe a plataforma do <b>E-Commerce</b>");
        return false;
      }

      return true;
    },
    isValidUrl() {
      this.ValidUrl = this.regexUrl.test(this.ecommerce.Url);
    },
  },
  created() {
    this.setEcommerceCurrent();
  },
  mounted() {
    this.isValidUrl()
  },
};
</script>

<style>
.showPassword {
  display: block;
  margin: auto;
}
</style>
